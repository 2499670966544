import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

const imageHero =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fprivacy-policy%2FPoli%CC%81tica%20de%20privacidad.png?alt=media&token=8bc241c6-0579-4d4b-949f-59cbf4f80271";

export const Hero = styled.header`
  background-image: url("${imageHero}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 8rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 0 16px;
  }

  ::after {
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.33;
  }

  article {
    position: relative;
    z-index: 1;
  }
`;

export const AboutUsSection = styled.section`
  padding: 36px 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 36px 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 36px 16px 10rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }



  .about-us-resume {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: auto auto;
    margin-bottom: 10rem;

    @media (max-width: 920px) {
      grid-gap: 6rem;
      grid-template-columns: 1fr;
    }

    article {
      width: 100%;
      max-width: 566px;
      align-self: flex-start;
      justify-content: flex-start;
     
      }
    }

    figure {
      max-width: 20rem;
      width: 100%;
      align-self: center;
      justify-self: center;
        margin-top: 15rem;
        @media (max-width: 920px) {
            margin: auto;
            
        }
      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .about-us-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(288px, 100%), 288px));
    grid-gap: 8rem 3.2rem;
    justify-content: space-between;
    max-width:1300px;
    width: 100%;
    margin-bottom: 10rem;

    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 16px;
        
    }
  }

  .about-us-footer {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  
`;

export const InfoSection = styled.section`
  padding: 12rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  .info-section {
    > h2 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 2.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      margin-bottom: 1.6rem;
    }

    > p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 2.3rem;
      line-height: 2.9rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 4rem;
    }
  }

  .info-section-list {
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;
    a {
        font-family: ${fonts.sofiaProRegular};
        font-size: 18px;
        color: #003D52;
    }
    li{
        font-family: ${fonts.sofiaProRegular};
        font-size: 18px;
        color: #003D52;
    }
    h5 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 1.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      margin-bottom: 2.4rem;
    }

    b {
      font-family: ${fonts.SofiaProBold};
    }

    h6 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 20px;
        color: #003D52;
      margin-bottom: 10px;
    }

      ul {
          padding-left: 0; 
          list-style: none; 
      }

      ul li {
          position: relative;
          padding-left: 30px; 
      }

      ul li::before {
          content: '•'; 
          font-size: 24px;
          position: absolute; 
          left: 0; 
          top: 0; 
          line-height: 1; 
      }


      p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 18px;
      line-height: 2.8rem;
      color: #003D52;
      margin-bottom: 4rem;
    }
  }
`;
